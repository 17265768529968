:root {
  --primary-600: #34363c;
  --primary-700: #252a31;
  --primary-800: #141b21;
  --cta-600: #ff1b69;
  --cta-700: #e7004f;
  --cta-800: #900031;
  --info-600: #31b0d5;
  --info-700: #1f9bbf;
  --info-800: #0e87ab;
  --success-600: #1ab744;
  --success-700: #1b9b4a;
  --success-800: #0b8738;
  --warning-600: #ffca28;
  --warning-700: #ffb302;
  --warning-800: #ff8f00;
  --danger-600: #d32f2f;
  --danger-700: #c62828;
  --danger-800: #b71b1c;
}
