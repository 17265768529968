.ws-cancel-plan-discount {
  background: var(--white);

  &__items,
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}
