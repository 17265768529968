.ws-payment-methods-action-item {
  background: var(--token-white-bg);
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 0 4px var(--token-grey-100);
    cursor: pointer;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ws-payment-method-radio-item {
  background: var(--token-white-bg);
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 0 4px var(--token-grey-100);
    cursor: pointer;
  }

  &__content {
    display: flex;
  }

  &__captions {
    flex-grow: 1;
  }
}
