.ws-mainmenu {
  &:not(:last-child):not(&--submenu) {
    margin-right: 16px;
  }

  &--submenu {
    margin-right: 8px;

    &:last-child {
      margin-right: -8px;

      .ws-dropdown {
        &__content-wrap {
          right: 0;
        }
      }
    }
  }

  &:not(:first-child):not(.ws-mainmenu--site-traffic) {
    margin-left: 16px;
  }

  &--submenu {
    .ws-ui-action-button--icon-after .ws-ui-action-button__label {
      margin-right: 0;
    }
  }

  // this is temporary styles for dropdown, we will delete them after implementation dropdown component in design system
  .ws-dropdown {
    &__content {
      padding: 0;
      box-sizing: border-box;
      overflow-y: visible;
    }

    &__content-wrap {
      width: 244px;
      position: absolute;
      right: -10px;
      border: none;
      background: transparent;
      border-radius: 0;
      overflow-y: auto;
      &:before,
      &:after {
        content: '';
        display: none;
        width: 0;
        height: 0;
        position: absolute;
      }
    }
  }

  &--sites-list {
    .ws-dropdown {
      &__content-wrap {
        right: unset;
        left: -19px;
      }
    }
  }

  &__description {
    font-family: var(--font-family-base);
    box-sizing: border-box;
    width: 264px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 32px;
    text-align: center;
    background-image: linear-gradient(90deg, var(--gray-100) 1%, var(--white) 12%);
    border-radius: 0 8px 8px 0;
  }

  &__description-image {
    margin-bottom: 20px;
  }

  &__description-title {
    font-size: 18px;
    color: var(--primary-700);
    text-align: center;
    line-height: 28px;
  }

  &__description-text {
    font-size: 14px;
    color: var(--gray-600);
    text-align: center;
    line-height: 24px;
  }

  &__blank {
    height: 100%;
  }

  &--hidden {
    max-width: 0;

    .ws-dropdown {
      min-height: 100%;
      border: none;
    }
  }
}
