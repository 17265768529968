.ws-site {
  height: 100%;
  position: relative;
  background-color: var(--token-grey-0);
  border: 1px solid var(--token-grey-300);
  box-shadow: 0 0 0 8px rgba(247, 248, 250, 0);
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 175ms ease-in-out;

  &:hover {
    box-shadow: 0 0 0 8px var(--token-grey-100);
  }

  &--loading {
    pointer-events: none;
    border-color: var(--token-grey-100);

    .ws-site__upsell {
      background: transparent;
      border-top: 1px solid var(--token-grey-100);
    }
  }

  &__details,
  &__upsell {
    padding: 16px 20px;
  }

  &__preview {
    position: relative;
    height: 224px;
    background-color: var(--token-grey-100);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    cursor: pointer;

    &--restricted {
      cursor: default;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__preview-actions {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    display: flex;
    gap: 8px;
  }

  &__popover-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 12px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__details-domain {
    display: flex;
    gap: 8px;
  }

  &__upsell {
    background: rgba(0, 94, 217, 0.03);
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  &__upsell-details {
    min-width: 0;
  }

  &__publish-action {
    display: flex;
  }
}
