.ws-plan-action-item {
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;
  cursor: pointer;

  &--loading {
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 0 0 0 4px var(--token-grey-100);
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__type {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}
