.ws-cancel-plan-confirmation-additional-changes {
  display: grid;
  gap: 8px;

  &--two-columns {
    grid-template-columns: 1fr 1fr;
  }
}

.ws-cancel-plan-confirmation-plan {
  border-radius: 8px;
  border: 1px solid var(--token-grey-300);

  &__details {
    display: flex;
    justify-content: space-between;
  }
}
