.ws-billing-item {
  display: grid;
  grid-template-columns: 1.5fr 2.5fr 1.5fr 1.5fr 1fr;
  gap: 40px;
  align-items: start;

  &__payment-method {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 4px;
  }
}
