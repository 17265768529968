.ws-site-list-error {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 0 24px;
  background-color: var(--token-grey-0);
  border: 1px solid var(--token-grey-300);
}
