@import url('~normalize.css');
@import '~@yola/ws-ui/src/styles.scss';
@import '~@yola/webapp-plugins/src/styles.scss';
@import 'src/scss/common/variables/index.scss';
@import 'src/js/common/components/styles.scss';
@import 'src/js/modules/copyright-footer/components/copyright-footer/styles.scss';
@import 'src/js/modules/domain-selector/components/domain-selector/styles.scss';
@import 'src/js/modules/dashboard/styles.scss';
@import 'src/js/modules/profile/styles.scss';
@import 'src/js/modules/dialogs/styles.scss';
@import 'src/js/modules/account-management/styles.scss';
@import 'src/js/modules/upsell-banners/styles.scss';

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;

  @media screen and (max-width: $screen-width-xl) {
    -webkit-text-size-adjust: none; // disable iOS automatic font size adjustment, should be removed after adaptive design for mobile devices will be empletented
  }
}

#app {
  overflow-x: hidden;
}
