.ws-cancel-plan-modal {
  --header-height: 48px;
  --button-group-height: 64px;

  background: var(--white);

  &__content {
    height: calc(100% - var(--header-height) - var(--button-group-height));
  }
}
