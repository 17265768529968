@import './components/subscription-promo/styles.scss';
@import './components/subscription-item/styles';
@import './components/subscriptions-list-header/styles';
@import './components/plan-features-row/styles.scss';
@import './components/plan-payment-details/styles.scss';
@import './components/plan-action-item/styles.scss';
@import './components/billing-item/styles.scss';
@import './components/billings-list-header/styles.scss';
@import './components/payment-method-item/styles.scss';
@import './components/payment-methods-header/styles.scss';
@import './components/payment-method-form-dialog/styles.scss';
@import './components/subscription-expired-dialog/styles.scss';
@import './components/delete-payment-method-dialog/styles.scss';
@import './components/delete-default-payment-method-dialog/styles.scss';
@import './components/set-default-payment-method-dialog/styles.scss';
@import './components/cancel-plan-modal/styles.scss';
@import './components/cancel-plan-proposals-item/styles.scss';
@import './components/cancel-plan-confirmation/styles.scss';
@import './components/cancel-plan-discount/styles.scss';
@import './components/cancel-plan-discount-item/styles.scss';

.ws-account-management-content {
  display: flex;
  gap: 40px;

  & > *:first-child {
    flex-grow: 1;
  }

  .ws-ui-tab-content {
    overflow: visible;
  }
}

.ws-account-management-modal {
  height: auto !important;
  background: var(--token-white-bg);
}

.ws-account-management-loader-wrapper {
  display: flex;
  justify-content: center;
  height: calc(100% - 48px);
}

.ws-cancel-subscriptions-content {
  max-width: 744px;
  margin-left: auto;
  margin-right: auto;

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
