.ws-subscription-promo {
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;

  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
