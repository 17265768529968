.ws-legacy-label {
  position: relative;
  display: inline-flex;
  justify-content: center;

  width: 44px;
  height: 14px;
  padding: 3px 6px;

  background: #fff;

  color: #30373d;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;

  cursor: default;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #dadde0;
    border-radius: 4px;
  }
}
