.ws-cancel-plan-proposals-item {
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;
  padding: 24px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
