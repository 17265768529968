.ws-copyright-footer {
  padding: 14px 16px;
  background-color: var(--token-grey-100);
  box-sizing: border-box;

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 6px;
    width: 100%;
    max-width: var(--ws-max-content-width);
  }

  &__links {
    a + a {
      position: relative;
      padding-left: 15px;

      &::after {
        content: '';
        position: absolute;
        display: block;
        left: 7px;
        top: 4px;
        width: 1px;
        height: 12px;
        background-color: var(--token-grey-300);
      }
    }
  }
}
