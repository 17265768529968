.ws-icon-status {
  display: flex;
  gap: 8px;

  &--right {
    justify-content: flex-end;
  }

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }
}
