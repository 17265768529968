.ws-site-creation {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 0 24px;
  border-radius: 8px;
  background-color: var(--token-grey-0);
  border: 1px solid var(--token-grey-300);

  &__preview {
    width: 295px;
    height: 240px;
    flex-shrink: 0;

    img {
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }
}
