.ws-payment-method-item {
  background: var(--token-white-bg);
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 0 4px var(--token-grey-100);
  }

  &__content {
    display: grid;
    gap: 16px;
    align-items: center;
    grid-template-columns: 64px 3fr 1fr 1fr;
  }

  &__buttons {
    display: flex;
    justify-content: end;
    gap: 8px;
  }
}
