.ws-site-list {
  &__grid {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;

    &--no-sites {
      grid-template-columns: 2fr 1fr;
    }
  }

  &__grid-item {
    box-sizing: border-box;
    min-height: 384px;
    border-radius: 8px;
    min-width: 0;
  }
}
