.ws-plan-features-list-row {
  display: grid;
  gap: 24px;
  grid-template-columns: 264px max-content max-content;

  &__content > *:first-child {
    display: flex;
  }

  .ws-ui-text--mark {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}
