.ws-set-default-payment-method-modal {
  &__item {
    background: var(--token-white-bg);
    border: 1px solid var(--token-grey-300);
    border-radius: 8px;
  }

  &__item-content {
    display: flex;
  }

  &__item-captions {
    flex-grow: 1;
  }
}
