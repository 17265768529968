.ws-plan-payment-details {
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item:not(:last-child) {
    border-right: 1px solid var(--token-grey-300);
  }

  &__loader {
    display: flex;
    min-height: 24px;
    align-items: center;
  }

  &__payment-method {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}
