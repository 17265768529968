.ws-page-container {
  display: flex;
  flex-direction: column;
  background: var(--token-grey-0);
  min-height: 100vh;
  min-width: var(--ws-page-min-width);
}

.ws-page-content {
  box-sizing: border-box;
  max-width: var(--ws-max-content-width);
  width: 100%;
  margin: 0 auto;
  padding: 72px 16px;
  flex: 1 0 auto;
}

.ws-page-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;

  &__right:only-child {
    margin-left: auto;
  }
}
