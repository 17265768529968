:root {
  --ws-navbar-height: var(--token-P016--height);
}

.ws-my-navbar {
  min-width: var(--ws-page-min-width);
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: var(--ws-navbar-height);
  background-color: var(--token-P016--color-bg);
  padding-left: var(--token-P016--padding-left);
  padding-right: var(--token-P016--padding-right);
  border-bottom: solid var(--token-P016--color-border) var(--token-P016--border-width);
  gap: var(--token-P016--offset);

  @media screen and (min-width: $screen-width-xl) {
    padding-left: var(--token-P016-lg--padding-left);
    padding-right: var(--token-P016-lg--padding-right);
  }

  &__left {
    display: grid;
    grid-auto-flow: column;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__menu-wrapper {
    display: flex;
    align-items: center;

    .ws-my-navbar__menu {
      margin-right: var(--token-P016-menu-divider--padding-right);
    }
  }

  &__divider {
    display: block;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: var(--token-P016--height);
      transform: translateY(-50%);
      width: var(--token-P016--border-width);
      background: var(--token-P016--color-border);
    }
  }

  &__nav {
    display: grid;
    grid-auto-flow: column;
    align-content: center;

    margin-left: var(--token-P016-divider-nav-md--padding-right);
    gap: var(--token-P016-navigation--spacing);
  }

  &__nav-item {
    display: flex;

    &--right-spacing {
      margin-right: 16px;
    }
  }

  &__logo {
    display: flex;
    margin-right: var(--token-P016-menu-divider-lg--padding-right);
    margin-left: var(--token-P016-menu-logo--spacing-between);
  }

  * + &__upgrade-btn {
    margin-left: 16px;
  }
}
