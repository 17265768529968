.ws-cancel-plan-discount-item {
  --discount-item-content-width: 276px;
  --discount-item-content-height: 124px;
  --default-border: 1px;
  --cta-border: 8px;

  width: var(--discount-item-content-width);
  height: var(--discount-item-content-height);
  display: flex;
  align-items: center;
  border: var(--default-border) solid var(--token-grey-300);
  border-radius: 8px;
  border-right: none;

  &--active {
    &::after {
      content: '';
      width: calc(var(--discount-item-content-width) - var(--cta-border));
      height: calc(var(--discount-item-content-height) - var(--cta-border));
      border: var(--cta-border) solid var(--token-cta-700);
      border-radius: 12px;
      position: absolute;
      transform: translateX(calc(var(--cta-border) * -1));
    }
  }

  &--strikethrough-text {
    font-weight: 400;
  }
}
