.ws-payment-method-form {
  &__field-row {
    display: flex;
    gap: 16px;

    & > * {
      flex: 1 1 50%;
    }
  }

  &__secure-label {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
  }

  &__security-code-title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__security-code-field {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__security-code-input {
    flex-grow: 1;
  }

  &__form {
    position: relative;
  }

  &__stripe-placeholder {
    min-height: 184px;
    max-height: 184px;
    margin-bottom: -4px;
  }

  &__loader {
    position: absolute;
    z-index: 2;
    background-color: white;
    border-radius: var(--border-radius-md);

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
