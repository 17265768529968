.ws-domain-search {
  background: rgba(0, 94, 217, 0.03);
  padding: 0 32px;
  border: 1px solid rgba(0, 94, 217, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 0 8px rgba(247, 248, 250, 0);
  transition: box-shadow 175ms ease-in-out;
  min-height: 100%;

  &:hover {
    box-shadow: 0 0 0 8px var(--token-grey-100);
  }

  &__search-group {
    display: flex;
    gap: 8px;
  }

  &__input {
    flex-grow: 1;

    .ws-ui-input-group-field,
    .ws-ui-input-group-field:hover {
      background-color: var(--token-grey-0) !important;
    }
  }
}
