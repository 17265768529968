.ws-subscription-item {
  background: var(--token-white-bg);
  border: 1px solid var(--token-grey-300);
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 0 4px var(--token-grey-100);
  }

  &--with-notification {
    .ws-subscription-item__content {
      padding-bottom: 8px;
    }
  }

  &--disabled {
    &:hover {
      pointer-events: none;
      box-shadow: none;
    }

    .ws-subscription-item__content {
      cursor: auto;
    }
  }

  &__content {
    padding: 16px;
    display: grid;
    gap: 16px;
    align-items: center;
    grid-template-columns: 2.5fr 1fr 1.5fr 24px;
    cursor: pointer;
  }
}
