.ws-subscription-expired-dialog {
  &__close-button {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
  }

  &__icon {
    display: flex;
    justify-content: center;
  }
}
